import React, { useState, useEffect } from "react";
import SidebarItem from "../SidebarItem/SidebarItem";
import GuideTooltip from "../GuideTooltip";
import { useNavigate } from "react-router-dom";
import { IoMdWallet } from "react-icons/io";
import { MdClose, MdLogout, MdMenu } from "react-icons/md";
import { BsBank } from "react-icons/bs";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { logout } from "../../store/slices/userSlice/userSlice";
import { roles } from "../../navigation/navigation";
import { CompanyAPI } from "../../api/Company/Company";
import { ICompanyConf } from "../../store/slices/companySlice/companySlice";
import Modal from "../Modal/Modal";
import ModalCompany from "../ModalCompany";
import { nextStep, setStep } from "../../store/slices/guideSlice";

const Sidebar = () => {
  const user = useAppSelector((state) => state.user.user);
  const { isGuideActive, currentStep, steps } = useAppSelector(
    (state) => state.guide
  );
  const [company, setCompany] = useState<ICompanyConf>();
  const dispatch = useAppDispatch();
  const [active, setActive] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [saveStep, setSaveStep] = useState<number | null>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const toggleActive = () => {
    setActive(!active);
  };

  const controlGuideline = () => {
    const menuArr = [1, 3, 6, 13];
    if (
      !isModalOpen &&
      width <= 1200 &&
      !active &&
      menuArr.includes(currentStep)
    ) {
      setSaveStep(currentStep);
      dispatch(setStep(0));
    } else if (!isModalOpen && width <= 1200 && active && currentStep === 0) {
      if (!saveStep) {
        dispatch(nextStep());
      } else {
        dispatch(setStep(saveStep));
      }
    } else if (
      !menuArr.includes(currentStep) &&
      width > 1200 &&
      currentStep === 0
    ) {
      setTimeout(() => dispatch(setStep(Boolean(saveStep) ? saveStep : 1)), 0);
      setActive(false);
    }
  };

  useEffect(() => {
    controlGuideline();
  }, [
    active,
    width,
    isModalOpen,
    currentStep,
    saveStep,
    dispatch,
    nextStep,
    setStep,
    setActive,
    setSaveStep,
  ]);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  const handleLogout = () => {
    dispatch(logout());
  };
  const getCompanyInfo = () => {
    CompanyAPI.get(user.company_id).then((res) => setCompany(res.data));
  };
  useEffect(() => {
    getCompanyInfo();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <aside
        className={`sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark d-flex flex-column align-items-stretch justify-content-between ${
          active && "active"
        } hideScroll`}
        id="sidenav-main"
      >
        <div className="position-relative">
          {currentStep === 1 && <GuideTooltip top={1} left={170} />}
          <ul className="navbar-nav py-2 hideScroll">
            {user && user.id ? (
              <li className="nav-item mb-2 mt-0 pt-0">
                <div
                  onClick={() => {
                    if (isGuideActive && currentStep === 1) {
                      dispatch(nextStep());
                    }
                    setIsModalOpen(true);
                  }}
                  className="nav-link text-white border-radius-xl cursor"
                >
                  {user.avatar_url ? (
                    <img
                      src={user.avatar_url}
                      className="avatar"
                      alt="avatar"
                    />
                  ) : (
                    <div className="avatar dark-version"></div>
                  )}
                  {/* {steps[currentStep]?.id === "step-1" && (
                    <div
                      style={{
                        position: "absolute",
                        background: "transparent",
                        padding: "10px",
                        border: "1px solid gray",
                        zIndex: 1000,
                      }}
                    >
                      {steps[currentStep].tooltipContent}
                    </div>
                  )} */}
                  <div>
                    <div>
                      <span className="nav-link-text ms-2 ps-1 cursor">
                        {user.name}
                      </span>
                    </div>
                    <div className="flex gap-3 justify-around ">
                      <span onClick={handleLogout} className="ms-2 mr-2 ps-1">
                        <MdLogout size={14} style={{ marginBottom: 3 }} />
                      </span>
                      <span className="m-2">{company?.balance + " ₽"}</span>
                    </div>
                  </div>
                </div>
                <hr className="horizontal my-0 light" />
              </li>
            ) : (
              ""
            )}
            <SidebarItem to="/dashboard" name="Журнал событий" />
            <SidebarItem to="/finances" name="Финансы" />
            <SidebarItem to="/" name="Объекты" />
            <SidebarItem to="/checking" name="Обходы" />
            <SidebarItem to="/event" name="События" />
            <SidebarItem to="/shift" name="Смена" />
            {/* <p className='px-3 py-0 my-0 mb-2'>Справочники</p> */}
            <SidebarItem to="/employee" name="Охранники" />
            {[roles.admin].includes(user.role) || user.company_id === 1 ? (
              <>
                <p className="px-3 py-0 my-0 mb-2">Админ</p>
                <SidebarItem to="/companies" name="Компании" />
                <SidebarItem to="/report" name="Отчёт" />
              </>
            ) : (
              false
            )}

            {[roles.director, roles.headOfSecurity, roles.admin].includes(
              user.role
            ) ? (
              <>
                <p className="px-3 py-0 my-0 mb-2">Настройки</p>
                <SidebarItem to="/user" name="Пользователи" />
              </>
            ) : (
              false
            )}
            {/*<SidebarItem to='/role' name='Роли' />*/}
          </ul>
        </div>
      </aside>
      {width < 1200 && (
        <div className="position-relative">
          {currentStep === 0 && <GuideTooltip top={16} left={52} />}
          <div
            className="menu-burger card p-1 position-fixed start-0 cursor"
            style={{ zIndex: 10000, top: 60 }}
            onClick={toggleActive}
          >
            {active ? (
              <MdClose size={20} color="#fff" />
            ) : (
              <MdMenu size={20} color="#fff" />
            )}
          </div>
        </div>
      )}
      <Modal
        open={isModalOpen}
        closeModal={function (): void {
          setIsModalOpen(false);
        }}
        // width={75}
        // translateX={0}
      >
        <ModalCompany
          handleClose={() => {
            setIsModalOpen(false);
            window.location.reload();
          }}
          mode="edit"
          item={{
            ...company,
            ...user,
            company_name: company?.name,
            company_phone: company?.phone,
          }}
        />
      </Modal>
    </div>
  );
};

export default Sidebar;
