import React, { useEffect, useRef, useState } from "react";
import "react-medium-image-zoom/dist/styles.css";
import "./assets/css/material-dashboard-pro.css";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import { roles, router } from "./navigation/navigation";
import { YMaps } from "react-yandex-maps";
import { useAppDispatch, useAppSelector } from "./store/store";
import { getUser } from "./store/slices/userSlice/userSlice";
import FloatingButton from "./components/FloatingButton";
import { resetGuide } from "./store/slices/guideSlice";
import ModalGuideEnded from "./components/ModalGuideEnded";
import Modal from "./components/Modal/Modal";

function App() {
  const dispatch = useAppDispatch();
  const { isGuideActive, isGuideFinished, currentStep } = useAppSelector(
    (state) => state.guide
  );
  const [open, setOpen] = useState(false);
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    const id = localStorage.getItem("userId") as string;
    dispatch(getUser(id));
  }, []);

  useEffect(() => {
    setOpen(isGuideFinished);
  }, [isGuideFinished]);

  useEffect(() => {
    if (!user?.company && !isGuideFinished && currentStep === 0) {
      console.log({ user });
      dispatch(resetGuide());
    }
  }, [user?.company, isGuideFinished]);

  // useEffect(() => {
  //   if (user) {
  //     if (user.role === roles.client) {
  //       if (!objects) {
  //         dispatch(getObjects({ limit: 20, offset: 0, query_and: [{ input: 'client_id', value: user.id }] }));
  //         objectsLoaded.current = true;
  //       } else if (!employees) {
  //         dispatch(getEmployees({ limit: 20, offset: 0 }));
  //       }

  //     } else {
  //       dispatch(getObjects({ limit: 20, offset: 0 }));
  //       dispatch(getEmployees({ limit: 20, offset: 0 }));
  //       objectsLoaded.current = true;
  //     }
  //   }
  // }, [user, objects, employees])

  return (
    <YMaps>
      <div className="g-sidenav-show bg-gray-200 dark-version fullHeight">
        <RouterProvider router={router} />
        {!isGuideActive ? (
          <FloatingButton onClick={() => dispatch(resetGuide())} />
        ) : null}
        <Modal
          open={open}
          closeModal={() => setOpen(false)}
          height={25}
          width={50}
          translateX={50}
          small
        >
          <ModalGuideEnded handleClose={() => setOpen(false)} />
        </Modal>
      </div>
    </YMaps>
  );
}

export default App;
