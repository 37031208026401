import { useEffect, useState } from 'react';
import { BsClipboardData, BsMap } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { IAddress } from '../../components/AddAdressesForm/AddAddressesForm';
import CardEvent from '../../components/CardEvent/CardEvent';
import Maps from '../../components/Maps/Maps';
import Modal from '../../components/Modal/Modal';
import ModalObject from '../../components/ModalObject/ModalObject';
import Sidebar from '../../components/Sidebar/Sidebar';
import { roles } from '../../navigation/navigation';
import {
  getCheckings,
  ICheckingConf
} from '../../store/slices/checkingSlice/checkingSlice';
import { getEmployees } from '../../store/slices/employeeSlice/employeeSlice';
import { getEvents } from '../../store/slices/eventSlice/eventSlice';
import { getObject, getObjects } from '../../store/slices/objectSlice/objectSlice';
import { getShifts } from '../../store/slices/shiftSlice/shiftSlice';
import { getUserInfo, getUsers } from '../../store/slices/userSlice/userSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';

const ObjectPage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const object = useAppSelector((state) => state.object.currentItem);
  const checking = useAppSelector((state) => state.checking.data);
  const shift = useAppSelector((state) => state.shift.data);
  const objects = useAppSelector((state) => state.object.data);
  const events = useAppSelector((state) => state.event.data);
  const employees = useAppSelector((state) => state.employee.data);
  const users = useAppSelector((state) => state.user.data);
  const user = useAppSelector((state) => state.user.user);
  // const client = useAppSelector((state) => state.user.currentUser);
  const dataRequested = useAppSelector((state) => state.shift.isRequested);
  const eventsRequested = useAppSelector((state) => state.event.isRequested);
  const objectsRequested = useAppSelector((state) => state.object.isRequested);
  const employeesRequested = useAppSelector((state) => state.employee.isRequested);
  const checkingsRequested = useAppSelector((state) => state.checking.isRequested);

  const client = users.find(({ id }) => id === object?.client_id);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [coords, setCoords] = useState([]);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const openModal2 = (item: ICheckingConf) => {
    setCoords(item.coords ? JSON.parse(item.coords) : []);
    setOpen2(true);
  };
  const closeModal2 = () => setOpen2(false);


  useEffect(() => {
    if (!user) return;
    if (user.role === roles.client) {
      if (!objectsRequested) {
        dispatch(getObject(id!));
        dispatch(getObjects({ limit: 0, offset: 0, query_and: [{ input: 'client_id', value: user.id }] }));

      } else if (!eventsRequested && !checkingsRequested && !employeesRequested && !dataRequested) {

        dispatch(getShifts({ limit: 0, offset: 0, query: [{ input: 'object_id', value: Number(id!) }] }));

        dispatch(getEvents({ limit: 0, offset: 0, query: [{ input: 'object_id', value: Number(id!) }] }));

        dispatch(getCheckings({ limit: 0, offset: 0, query: [{ input: 'object_id', value: Number(id!) }] }));

        const securityIds: any = objects.map(object => {
          return JSON.parse(object.ohraniki).map((security: any) => security.value)
        });

        dispatch(getEmployees({ limit: 0, offset: 0, query: securityIds.flat().map((id: any) => ({ input: 'id', value: Number(id!) })) }));
      }

    } else if (!eventsRequested && !checkingsRequested && !employeesRequested && !objectsRequested && !objectsRequested && !dataRequested) {
      dispatch(getObject(id!));
      dispatch(getEmployees({ limit: 0, offset: 0 }));
      dispatch(getObjects({ limit: 0, offset: 0 }));

      dispatch(
        getShifts({
          limit: 0,
          offset: 0,
          query_and: [{ input: 'object_id', value: Number(id!) }],
        })
      );
      dispatch(
        getCheckings({
          limit: 0,
          offset: 0,
          query_and: [{ input: 'object_id', value: Number(id!) }],
        })
      );

      dispatch(
        getEvents({
          limit: 0,
          offset: 0,
          query_and: [{ input: 'object_id', value: Number(id!) }],
        })
      );
    }
  }, [dataRequested, user, objects, eventsRequested, checkingsRequested, employeesRequested, objectsRequested,]);

  useEffect(() => {
    dispatch(getUserInfo(Number(id!)));
    dispatch(getUsers({ limit: 0, offset: 0 }));

  }, [])



  const items = ([...shift.map(shift => ({ ...shift, itemType: 'shift' })), ...checking.map(checking => ({ ...checking, itemType: 'checking' })), ...events.map(event => ({ ...event, itemType: 'event' }))] as any).sort((a: any, b: any) => {
    return Date.parse(a.create_at ? a.create_at : a.datetime_start
    ) > Date.parse(b.create_at ? b.create_at : b.datetime_start) ? -1 : 1;

  });

  // let periodicity = '';
  // if (object && object.periodicity) {
  //   periodicity = JSON.parse(object.periodicity).toString();
  // }  

  const headOfSecurityObj = users.find(user => user.id == object?.head_of_security);
  const headOfSecurityName = headOfSecurityObj ? headOfSecurityObj.name : '';

  const access = [roles.director, roles.headOfSecurity].includes(user.role);

  return (
    <div>
      <Sidebar />
      <div className='avoidSidebar py-3 pe-3 ps-sm-3'>
        {object && (
          <div className='d-flex align-items-center justify-content-between mb-4'>
            <h6 className='m-0'>Информация</h6>
            {access ? (<button className='btn btn-info btn-sm m-0' onClick={openModal}>
              Изменить
            </button>) : ''}

          </div>
        )}
        <div className='row'>
          <div className='col-12'>
            <div className='card cursor mb-3'>
              <div className='card-header p-3 pt-2'>
                <div
                  className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-1'
                  style={{ width: 40, height: 40 }}
                >
                  <div className='mt-1'>
                    <BsClipboardData color='#ffffff' size={25} />
                  </div>
                </div>
                <div className='text-end ms-5'></div>
              </div>
              <div className='card-body mt-0 p-3'>
                <div className='row'>
                  <div className='col-12 col-md-6'>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Наименование</span>
                      <span className='text-sm'>{object?.name}</span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Описание</span>
                      <span className='text-sm'>{object?.description}</span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Охранники</span>
                      <div className='d-flex flex-column align-items-end'>
                        {object?.ohraniki[0] === '['
                          ? JSON.parse(object?.ohraniki).map(
                            (i: any, index: number) => (
                              <div key={index}>
                                <span className='text-sm'>{i.label}</span>
                              </div>
                            )
                          )
                          : object?.ohraniki}
                      </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>
                        Начальник охраны
                      </span>
                      <span className='text-sm'>
                        {headOfSecurityName}
                      </span>
                    </div>
                    {
                      access ? (<div className='d-flex align-items-center justify-content-between flex-wrap'>
                        <span className='text-sm text-white'>
                          Заказчик:
                        </span>
                        <span className='text-sm'>
                          {client ? client.name : ''}
                        </span>
                      </div>) : ''
                    }
                    {/* <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>
                        Периодичность обхода
                      </span>
                      <span className='text-sm'>{periodicity}</span>
                    </div> */}
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Длительность</span>
                      <span className='text-sm'>{object?.dlitelnost}</span>
                    </div>
                    {/* <div className='d-flex align-items-start justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>
                        График клининга
                      </span>
                      <div className='row ms-2'>
                        {object?.grafik_klininga &&
                          JSON.parse(object?.grafik_klininga!).map(
                            (day: any, index: any) => (
                              <div
                                key={index}
                                className='col-6 d-flex align-items-start justify-content-between'
                              >
                                <span className='text-sm'>{day.name}</span>
                                <span className='text-sm'>{day.value}</span>
                              </div>
                            )
                          )}
                      </div>
                    </div> */}
                    <div className='d-flex align-items-start justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>График обходов</span>
                      <div className='row w-100'>
                        {object?.chart &&
                          JSON.parse(object?.chart!).map(
                            (day: any, index: any) => (
                              <div key={index} className='col-6'>
                                <span className='text-sm'>{day.value}</span>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Наборы ключей</span>
                      <div className='row text-sm w-100'>
                        {object?.keyset[0] === '['
                          ? JSON.parse(object?.keyset).map(
                            (k: any, index: number) => (
                              <div key={index} className='col-6'>
                                <span className='text-sm'>{k.type}</span>
                              </div>
                            )
                          )
                          : object?.keyset}
                      </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Адреса</span>
                      <div className='row text-sm w-100'>
                        {object?.address[0] === '['
                          ? JSON.parse(object?.address).map(
                            (a: IAddress, index: number) => (
                              <div key={index} className='col-6'>
                                <span className='text-sm'>Улица: {a.street}, Дом: {a.house}</span>
                              </div>
                            )
                          )
                          : object?.address}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <p className='mb-0'>Смена</p> */}
        {/* <div className='d-flex align-items-center hideScroll overflowX pt-3 gap-3'>
          {shift.map((item, index) => (
            <div className='scrollCard' key={index}>
              <div className='card cursor mb-3'>
                <div className='card-header p-3 pt-2'>
                  <div
                    className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
                    style={{ width: 40, height: 40 }}
                  >
                    <div className='mt-1'>
                      <BsClipboardData color='#ffffff' size={25} />
                    </div>
                  </div>
                  <div className='text-end ms-5'></div>
                </div>
                <div className='card-body mt-0 p-3'>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Охраник</span>
                    <span className='text-sm'>
                      {employees.find((i) => i.id == item.guard_id)?.name ||
                        item.guard_id}
                    </span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Объект</span>
                    <span className='text-sm'>{object?.name}</span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Дата и время начала</span>
                    <span className='text-sm'>
                      {new Date(item.datetime_start!).toLocaleString()}
                    </span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Дата и время конца</span>
                    <span className='text-sm'>
                      {new Date(item.datetime_end!).toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div> */}

        {/* <p className='mb-0'>Обходы</p> */}

        {/* <div className='d-flex align-items-stretch hideScroll overflowX pt-3 gap-3 mb-3'>
          {checking.map((item, index) => (
            <div className='scrollCard' key={index}>
              <div className='card cursor mb-3 h-100'>
                <div className='card-header p-3 pt-2'>
                  <div
                    className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
                    style={{ width: 40, height: 40 }}
                  >
                    <div className='mt-1' onClick={() => openModal2(item)}>
                      <BsMap color='#ffffff' size={25} />
                    </div>
                  </div>
                  <div className='text-end ms-5'></div>
                </div>
                <div className='card-body mt-0 p-3'>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Охраник</span>
                    <span className='text-sm'>
                      {employees.find((i) => i.id == item.guard_id)?.name ||
                        item.guard_id}
                    </span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Объект</span>
                    <span className='text-sm'>{object?.name}</span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Дата и время начала</span>
                    <span className='text-sm'>
                      {new Date(item.datetime_start).toLocaleString('ru-RU')}
                    </span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Дата и время конца</span>
                    <span className='text-sm'>
                      {new Date(item.datetime_end).toLocaleString('ru-RU')}
                    </span>
                  </div>
                  {item.info &&
                    item.info[0] === '[' &&
                    JSON.parse(item.info)?.map((i: any, index: number) => (
                      <div
                        className='d-flex align-items-center justify-content-between flex-wrap'
                        key={index}
                      >
                        <span className='text-sm'>{i.title}</span>
                        <span className='text-sm'>
                          {typeof i.value === 'boolean'
                            ? i.value === true
                              ? 'Да'
                              : 'Нет'
                            : i.value}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div> */}
        {/* <p className='mb-0'>События</p> */}
        {/* // <div className='scrollCard' key={index}> */}
        {/* //   <CardEvent item={item} /> */}
        {/* <div className='card cursor mb-3'>
          //     <div className='card-header p-3 pt-2'>
          //       <div
          //         className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
          //         style={{ width: 40, height: 40 }}
          //       >
          //         <div className='mt-1'>
          //           <BsClipboardData color='#ffffff' size={25} />
          //         </div>
          //       </div>
          //       <div className='text-end ms-5'></div>
          //     </div>
          //     <div className='card-body mt-0 p-3'>
          //       <div className='d-flex align-items-center justify-content-between flex-wrap'>
          //         <span className='text-sm'>Охраник</span>
          //         <span className='text-sm'>
          //           {employees.find((i) => i.id == item.guard_id)?.name ||
          //             item.guard_id}
          //         </span>
          //       </div>
          //       <div className='d-flex align-items-center justify-content-between flex-wrap'>
          //         <span className='text-sm'>Объект</span>
          //         <span className='text-sm'>{object?.name}</span>
          //       </div>
          //       <div className='d-flex align-items-center justify-content-between flex-wrap'>
          //         <span className='text-sm'>Событие</span>
          //         <span className='text-sm'>{item.event}</span>
          //       </div>
          //       <div className='d-flex align-items-center justify-content-between flex-wrap'>
          //         <span className='text-sm'>Дата и время</span>
          //         <span className='text-sm'>
          //           {new Date(item.create_at!).toLocaleString('ru-RU')}
          //         </span>
          //       </div>
          //       <div className='mt-1'>
          //         {item.photo && JSON.parse(item.photo)?.length > 0 ? (
          //           <div className='d-flex align-items-center hideScroll overflowX image-container'>
          //             {JSON.parse(item.photo).map(
          //               (photo: any, index: number) => (
          //                 <img
          //                   key={index}
          //                   src={`${SERVER_URL}/get-file/${photo}`}
          //                   alt='preview'
          //                   className='w-100 card-image'
          //                 />
          //               )
          //             )}
          //           </div>
          //         ) : (
          //           <img
          //             src='https://whey.kz/wp-content/uploads/2020/11/placeholder.png'
          //             alt='preview'
          //             className='w-100'
          //           />
          //         )}
          //       </div>
          //     </div>
          //   </div> */}
        {/* // </div> */}
        {items.map((item: any, index: number) => (
          <div className='col-12 mb-3' >
            {
              item.itemType === 'checking' ? (<div className='card mb-3 h-100'>
                <div className='card-header p-3 pt-2'>
                  <div
                    className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
                    style={{ width: 40, height: 40 }}
                  >
                    <div className='mt-1' onClick={() => openModal2(item)}>
                      <BsMap color='#ffffff' size={25} />
                    </div>
                  </div>
                  <div className='text-end ms-5'></div>
                </div>
                <div className='card-body mt-0 p-3  d-flex  justify-content-between flex-wrap'>
                  <div className='d-flex align-items-center justify-content-between flex-wrap mx-1 flex-column'>
                    <span className='text-sm text-white'>Дата и время начала</span>
                    <span className='text-sm'>
                      {new Date(item.datetime_start).toLocaleString('ru-RU')}
                    </span>
                  </div>
                  <div className='d-flex flex-column align-items-center justify-content-between flex-wrap mx-1'>
                    <span className='text-sm text-white'>Событие</span>
                    <span className='text-sm'>
                      Обход
                    </span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap mx-1 flex-column'>
                    <span className='text-sm text-white'>Дата и время конца</span>
                    <span className='text-sm'>
                      {new Date(item.datetime_end).toLocaleString('ru-RU')}
                    </span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap mx-1 flex-column'>
                    <span className='text-sm text-white'>Объект</span>
                    <span className='text-sm'>{object?.name}</span>
                  </div>


                  {item.info &&
                    item.info[0] === '[' &&
                    JSON.parse(item.info)?.map((i: any, index: number) => (
                      <div
                        className='d-flex align-items-center justify-content-between flex-wrap mx-1 flex-column'
                        key={index}
                      >
                        <span className='text-sm text-white'>{i.title === 'Время' ? 'Время затрачено' : i.title}</span>
                        <span className='text-sm'>
                          {typeof i.value === 'boolean'
                            ? i.value === true
                              ? 'Да'
                              : 'Нет'
                            : i.value}
                        </span>
                      </div>
                    ))}
                  <div className='d-flex align-items-center justify-content-between flex-wrap mx-1 flex-column'>
                    <span className='text-sm text-white'>ФИО охранника</span>
                    <span className='text-sm'>
                      {employees.find((i) => i.id == item.guard_id)?.name ||
                        item.guard_id}
                    </span>
                  </div>
                </div>
              </div>) : item.itemType === 'event' ? <CardEvent key={index} item={item} fullWidth /> : (
                <div className='card mb-3'>
                  <div className='card-header p-3 pt-2'>
                    <div
                      className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
                      style={{ width: 40, height: 40 }}
                    >
                      <div className='mt-1'>
                        <BsClipboardData color='#ffffff' size={25} />
                      </div>
                    </div>
                    <div className='text-end ms-5'></div>
                  </div>
                  <div className='card-body mt-0 p-3 d-flex justify-content-between flex-wrap'>
                    <div className='d-flex align-items-center justify-content-between flex-wrap flex-column mx-1'>
                      <span className='text-sm text-white'>Дата и время начала</span>
                      <span className='text-sm'>
                        {new Date(item.datetime_start!).toLocaleString()}
                      </span>
                    </div>
                    <div className='d-flex flex-column align-items-center justify-content-between flex-wrap mx-1'>
                      <span className='text-sm text-white'>Событие</span>
                      <span className='text-sm'>
                        Смена
                      </span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap flex-column mx-1'>
                      <span className='text-sm text-white'>Дата и время конца</span>
                      <span className='text-sm'>
                        {new Date(item.datetime_end!).toLocaleString()}
                      </span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap flex-column mx-1'>
                      <span className='text-sm text-white'>Объект</span>
                      <span className='text-sm'>{object?.name}</span>
                    </div>


                    <div className='d-flex align-items-center justify-content-between flex-wrap flex-column mx-1'>
                      <span className='text-sm text-white'>ФИО охранника</span>
                      <span className='text-sm'>
                        {employees.find((i) => i.id == item.guard_id)?.name ||
                          item.guard_id}
                      </span>
                    </div>
                  </div>
                </div>

              )
            }

          </div>
        ))}


        <Modal
          open={open}
          closeModal={closeModal}

          children={
            <ModalObject mode='edit' handleClose={closeModal} item={object!} />
          }
          width={40}
          translateX={70}
        />
        <Modal
          open={open2}
          closeModal={closeModal2}
          children={
            <div>
              <Maps coords={coords} />
            </div>
          }
        />
      </div>
    </div >
  );
};

export default ObjectPage;
