import React, { useCallback, useEffect, useRef, useState } from "react";

interface IInput {
  value: string | number | undefined;
  defaultValue?: string | number | undefined;
  handleChange: (e: any) => void;
  handleEnter?: () => void;
  handleSubmit?: () => void;
  placeholder?: string;
  type?: string;
  autoComplete?: boolean;
  disabled?: boolean;
  name?: string;
  id?: string;
  style?: React.CSSProperties;
  selfSubmittable?: boolean;
}

const Input: React.FC<IInput> = ({
  value,
  handleChange,
  handleEnter,
  handleSubmit,
  placeholder,
  type = "text",
  autoComplete = false,
  disabled = false,
  style,
  name,
  defaultValue,
  selfSubmittable,
}) => {
  const [show, setShow] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && type === "password") {
      if (show) {
        inputRef.current.type = "text";
      } else {
        inputRef.current.type = "password";
      }
    }
  }, [show]);

  return (
    <div
      style={{ ...style, position: "relative", borderRadius: "6px" }}
      className={
        (value || type === "time"
          ? "input-group input-group-outline is-focused"
          : "input-group input-group-outline") +
        (autoComplete && !inputRef.current ? " is-focused" : "")
      }
    >
      <label className="form-label">{placeholder}</label>
      {type === "textarea" ? (
        <textarea
          value={value}
          onChange={handleChange}
          className="form-control"
          rows={1}
          name={name}
          autoComplete={autoComplete ? "true" : ("new-password" as string)}
        ></textarea>
      ) : (
        <input
          disabled={disabled}
          autoComplete={autoComplete ? "true" : ("new-password" as string)}
          style={disabled && value ? { border: "none" } : {}}
          value={value}
          defaultValue={defaultValue}
          ref={inputRef}
          name={name}
          onChange={handleChange}
          type={type}
          className={"form-control " + (autoComplete && "ct-nocomplete")}
          onKeyDown={(e) => {
            if (e.key === "Enter" && handleEnter) {
              handleEnter();
            }
          }}
        />
      )}
      {type === "password" && (
        <button
          onClick={() => setShow((prev) => !prev)}
          style={{
            zIndex: 5,
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
            position: "absolute",
            right: selfSubmittable ? "62px" : "10px",
            transform: "translateY(50%)",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="--bs-info"
            className="bi bi-eye-slash-fill"
            viewBox="0 0 16 16"
            style={{
              fill: "#1a73e8",
              display: show ? "block" : "none",
              cursor: "pointer",
            }}
          >
            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            className="bi bi-eye-fill"
            viewBox="0 0 16 16"
            style={{
              fill: "#1a73e8",
              display: show ? "none" : "block",
              cursor: "pointer",
            }}
          >
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
          </svg>
        </button>
      )}
      {selfSubmittable && handleSubmit && (
        <button
          className="btn btn-secondary"
          style={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
            margin: 0,
            padding: "9px 18px",
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
          }}
          onClick={handleSubmit}
        >
          {disabled ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-check-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
          )}
        </button>
      )}
    </div>
  );
};

export default Input;
