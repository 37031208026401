import React, { useEffect, useState } from "react";
import CardUser from "../../components/CardUser/CardUser";
import Modal from "../../components/Modal/Modal";
import ModalUser from "../../components/ModalUser/ModalUser";
import Pagination from "../../components/Pagination/Pagination";
import Sidebar from "../../components/Sidebar/Sidebar";
import {
  getAllUsers,
  getUsers,
  IUserConf,
} from "../../store/slices/userSlice/userSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { roles } from "../../navigation/navigation";
import Tooltip from "../../components/GuideTooltip";
import { nextStep } from "../../store/slices/guideSlice";

const UserPage = () => {
  const [open, setOpen] = useState(false);
  const user = useAppSelector((state) => state.user.user);
  const { currentStep } = useAppSelector((state) => state.guide);
  const allUsers = useAppSelector((state) => state.user.allUsers);
  const [open2, setOpen2] = useState(false);
  const [offset, setOffset] = useState(0);
  const [currentItem, setCurrentItem] = useState<IUserConf | null>(null);
  const data = useAppSelector((state) => state.user.data);
  const [usersData, setUsersData] = useState(data);
  const total = useAppSelector((state) => state.user.total);
  const [totalPages, setTotalPages] = useState(
    useAppSelector((state) => state.user.total)
  );
  const [width, setWidth] = useState(window.innerWidth);

  const dispatch = useAppDispatch();
  const [visiblePagination, setVisiblePagination] = useState(true);
  const access = [roles.admin].includes(user?.role);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const openModal2 = (item: IUserConf) => {
    setCurrentItem(item);
    setOpen2(true);
  };
  const handleResize = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const closeModal2 = () => setOpen2(false);
  useEffect(() => {
    setUsersData(data);
    setTotalPages(total);
  }, [data]);
  useEffect(() => {
    if (access) {
      dispatch(getUsers({ limit: 20, offset }));
      dispatch(getAllUsers({ limit: 1000, offset }));
    } else {
      dispatch(
        getUsers({
          limit: 20,
          offset,
          query_and: [{ input: "company_id", value: Number(user.company_id) }],
        })
      );
      dispatch(
        getAllUsers({
          limit: 20,
          offset,
          query_and: [{ input: "company_id", value: Number(user.company_id) }],
        })
      );
    }
  }, []);

  useEffect(() => {
    if (access) dispatch(getUsers({ limit: 20, offset }));
    else
      dispatch(
        getUsers({
          limit: 20,
          offset,
          query_and: [{ input: "company_id", value: Number(user.company_id) }],
        })
      );
  }, [offset]);

  const addUser = (user: IUserConf) => {
    setUsersData((prev) => [...prev, user]);
  };
  return (
    <div>
      <Sidebar />
      <div className="avoidSidebar py-3 pe-3 ps-sm-3">
        <div className="d-flex align-items-center justify-content-center gap-3 mb-4 position-relative">
          {currentStep === 7 && (
            <Tooltip
              left={
                width > 1200
                  ? "50%"
                  : width > 850
                  ? "40%"
                  : width > 550
                  ? "30%"
                  : width > 400
                  ? "20%"
                  : "10%"
              }
              top={70}
            />
          )}
          {currentStep === 10 && (
            <Tooltip
              left={
                width > 1200
                  ? "50%"
                  : width > 850
                  ? "40%"
                  : width > 550
                  ? "30%"
                  : width > 400
                  ? "20%"
                  : "10%"
              }
              top={70}
            />
          )}
          <button
            className="btn btn-info btn-sm m-0"
            onClick={() => {
              openModal();
              if (currentStep === 7 || currentStep === 10) dispatch(nextStep());
            }}
          >
            Добавить
          </button>
          <div className="serach">
            <input
              placeholder="Поиск..."
              onChange={(e) => {
                if (e.target.value === "") {
                  setUsersData(data);
                  setTotalPages(total);
                  setVisiblePagination(true);
                  return;
                }
                const filteredUsers = allUsers.filter((user) =>
                  user?.login
                    ?.toLowerCase()
                    .includes(e.target.value.toLowerCase())
                );
                setUsersData(filteredUsers);
                setTotalPages(filteredUsers.length);
                setVisiblePagination(false);
              }}
              type="text"
            />
          </div>
        </div>
        <div className="row">
          {usersData.map((item: IUserConf, index: number) => (
            <div className="col-12 col-md-4" key={index}>
              <CardUser handleOpen={openModal2} item={item} />
            </div>
          ))}
        </div>
        {visiblePagination && (
          <Pagination
            offset={offset}
            total={totalPages}
            handleBack={() => {
              if (offset <= 0) return;
              setOffset(offset - 10);
            }}
            handleNext={() => {
              if (offset > totalPages) return;
              setOffset(offset + 10);
            }}
            handleChose={(page) => {
              setOffset(page);
            }}
          />
        )}
        <Modal
          open={open}
          closeModal={closeModal}
          children={
            <ModalUser
              mode="create"
              addUser={addUser}
              handleClose={closeModal}
            />
          }
          width={40}
          translateX={70}
        />
        <Modal
          open={open2}
          closeModal={closeModal2}
          children={
            <ModalUser
              mode="edit"
              handleClose={closeModal2}
              item={currentItem!}
            />
          }
          width={40}
          translateX={70}
        />
      </div>
    </div>
  );
};

export default UserPage;
