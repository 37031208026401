import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import Input from "../Input/Input";
import {
  ICompanyConf,
  updateCompany,
} from "../../store/slices/companySlice/companySlice";
import { nextStep } from "../../store/slices/guideSlice";
import Tooltip from "../GuideTooltip";
import { UserAPI } from "../../api/User/User";
import { updateUser } from "../../store/slices/userSlice/userSlice";
import { validationPassword } from "../../utils/validationPassword";
interface IModalCompany {
  mode: "edit" | "create" | "view";
  item?: ICompanyConf;
  handleClose: () => void;
}

const ModalCompany: React.FC<IModalCompany> = ({ mode, handleClose, item }) => {
  const [companyData, setCompanyData] = useState<ICompanyConf>({
    name: "",
    email: "",
    title: "",
    inn: undefined,
    kpp: undefined,
    current_account: undefined,
    bic: undefined,
    bank: "",
    ceo: "",
    phone: "",
    address: "",
    terms: false,
  });

  const [checkData, setCheckData] = useState({ login: "", password: "" });

  const [width, setWidth] = useState(window.innerWidth);
  const [isVisible, setIsVisible] = useState(false);
  const targetRef = useRef(null);
  const [passErrorMessage, setPassErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1,
        rootMargin: "0px 0px -80px 0px",
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dispatch = useAppDispatch();
  const { isGuideActive, currentStep, steps } = useAppSelector(
    (state) => state.guide
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    console.log({ value, name, e: e.target });
    setCompanyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckChange = (e: any) => {
    const { name, value } = e.target;
    console.log({ value, name, e: e.target });
    setCheckData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTick = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { checked } = e.target as HTMLInputElement;
    setCompanyData((prevData) => ({
      ...prevData,
      terms: checked,
    }));
  };

  const handleSubmit = async () => {
    try {
      await dispatch(
        updateCompany({ ...companyData, name: companyData.title })
      );
      await dispatch(
        updateUser({
          name: companyData.name,
          login: companyData.login,
          id: item?.id,
        })
      );
    } catch (error) {
      console.log(error);
    }
    handleClose();
  };
  //TODO change login and pass instead of checking
  const changeCredentials = async () => {
    const validationError = validationPassword(checkData.password);
    if (validationError) {
      setPassErrorMessage(validationError);
      return;
    }
    if (item?.login !== checkData.login) {
      const aproveLogin = await UserAPI.checkEmail(checkData.login);
      if (aproveLogin.status === false) {
        alert("Логин занят!");
        return;
      }
    }
    try {
      const res = await dispatch(updateUser({ ...checkData, id: item?.id }));
      console.log(res);
      if (res.payload.message === "success") {
        alert("Учетные данные были обновлены успешно!");
        setCheckData({ login: "", password: "" });
      }
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (mode === "edit" && item) {
      console.log({ item });
      setCompanyData({
        name: item?.name,
        email: item?.email,
        title: item?.company_name,
        login: item?.login,
        inn: item?.inn,
        kpp: item?.kpp,
        current_account: item?.current_account,
        bic: item?.bic,
        bank: item?.bank,
        ceo: item?.ceo,
        phone: item?.company_phone,
        address: item?.address,
        id: item?.company_id,
      });
    }
  }, [mode, item]);

  useEffect(() => {
    console.log({ companyData });
  }, [companyData]);

  return (
    <div className="px-1 flex-1 pb-2 h-100 d-flex flex-column">
      <h6>Моя компания</h6>
      <div className="d-flex flex-column justify-content-between flex-1 position-relative">
        <div className="d-flex flex-column gap-3 w-100">
          <div className="d-flex justify-content-between w-100 flex-md-nowrap flex-wrap">
            <div className="w-100 pe-md-2">
              <div className="mb-2">
                <label className="mb-1" htmlFor="name">
                  Название компании
                </label>
                <Input
                  id="name"
                  name="name"
                  value={companyData.name || ""}
                  handleChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <label className="mb-1" htmlFor="login">
                  Электронная почта
                </label>
                <Input
                  id="login"
                  name="login"
                  value={companyData.login || ""}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className="w-100 ps-md-2 d-flex flex-column gap-1">
              <h6>Реквизиты</h6>
              <div className="d-flex align-items-start gap-2">
                <label
                  htmlFor="title"
                  style={{
                    flex: 1,
                    display: "block",
                    marginBottom: "5px",
                    lineHeight: "16px",
                  }}
                >
                  Наименование предприятия
                </label>
                <Input
                  type="text"
                  id="title"
                  name="title"
                  style={{ flex: 3 }}
                  value={companyData.title}
                  handleChange={handleChange}
                />
              </div>

              <div className="d-flex align-items-start gap-2">
                <label
                  htmlFor="inn"
                  style={{ flex: 1, display: "block", marginBottom: "5px" }}
                >
                  ИНН
                </label>
                <Input
                  type="number"
                  id="inn"
                  name="inn"
                  style={{ flex: 3 }}
                  value={companyData.inn}
                  handleChange={handleChange}
                />
              </div>

              <div className="d-flex align-items-start gap-2">
                <label
                  htmlFor="kpp"
                  style={{ flex: 1, display: "block", marginBottom: "5px" }}
                >
                  КПП
                </label>
                <Input
                  type="number"
                  id="kpp"
                  name="kpp"
                  style={{ flex: 3 }}
                  value={companyData.kpp}
                  handleChange={handleChange}
                />
              </div>

              <div className="d-flex align-items-start gap-2">
                <label
                  htmlFor="current_account"
                  style={{
                    flex: 1,
                    display: "block",
                    marginBottom: "5px",
                    lineHeight: "16px",
                  }}
                >
                  Расчетный счет
                </label>
                <Input
                  type="number"
                  id="current_account"
                  name="current_account"
                  style={{ flex: 3 }}
                  value={companyData.current_account}
                  handleChange={handleChange}
                />
              </div>

              <div className="d-flex align-items-start gap-2">
                <label
                  htmlFor="bic"
                  style={{ flex: 1, display: "block", marginBottom: "5px" }}
                >
                  БИК
                </label>
                <Input
                  type="number"
                  id="bic"
                  name="bic"
                  style={{ flex: 3 }}
                  value={companyData.bic}
                  handleChange={handleChange}
                />
              </div>

              <div className="d-flex align-items-start gap-2">
                <label
                  htmlFor="bank"
                  style={{ flex: 1, display: "block", marginBottom: "5px" }}
                >
                  Банк
                </label>
                <Input
                  type="text"
                  id="bank"
                  name="bank"
                  style={{ flex: 3 }}
                  value={companyData.bank}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between flex-md-nowrap flex-wrap flex-column-reverse flex-md-row">
            <div className="w-100 pe-md-2">
              <div className="mb-2">
                <label className="mb-1" htmlFor="login">
                  Логин
                </label>
                <Input
                  id="login"
                  name="login"
                  disabled
                  value={checkData.login || companyData.login}
                  defaultValue={companyData.login}
                  handleChange={handleCheckChange}
                />
              </div>
              <div className="mb-2">
                <label className="mb-1" htmlFor="password">
                  Пароль
                </label>
                <Input
                  id="password"
                  name="password"
                  value={checkData.password || ""}
                  handleChange={handleCheckChange}
                  type="password"
                  selfSubmittable
                  disabled={disabled}
                  handleSubmit={
                    disabled ? () => setDisabled(false) : changeCredentials
                  }
                />
              </div>
              {passErrorMessage && (
                <div className="text-red  mt-2">{passErrorMessage}</div>
              )}
              <div>
                <p className="small mb-2 mb-md-0">
                  (Пароль должен быть не менее 8 символов. Заглавная и строчная
                  буква и спец.символ должны быть)
                </p>
              </div>
            </div>
            <div className="w-100 ps-md-2 d-flex flex-column gap-1">
              <div className="d-flex align-items-start gap-2">
                <label
                  style={{
                    flex: 1,
                    display: "block",
                    marginBottom: "5px",
                    lineHeight: "16px",
                  }}
                  htmlFor="ceo"
                >
                  ФИО руководителя
                </label>
                <Input
                  id="ceo"
                  name="ceo"
                  value={companyData.ceo || ""}
                  handleChange={handleChange}
                  style={{ flex: 3 }}
                />
              </div>
              <div className="d-flex align-items-start gap-2">
                <label
                  style={{
                    flex: 1,
                    display: "block",
                    marginBottom: "5px",
                  }}
                  htmlFor="phone"
                >
                  Телефон
                </label>
                <Input
                  id="phone"
                  name="phone"
                  value={companyData.phone || ""}
                  handleChange={handleChange}
                  style={{ flex: 3 }}
                />
              </div>
              <div className="d-flex align-items-start gap-2">
                <label
                  style={{
                    flex: 1,
                    display: "block",
                    marginBottom: "5px",
                  }}
                  htmlFor="address"
                >
                  Адрес
                </label>
                <Input
                  id="address"
                  name="address"
                  value={companyData.address || ""}
                  handleChange={handleChange}
                  style={{ flex: 3 }}
                />
              </div>
            </div>
          </div>
          <div className="mb-2" ref={targetRef}>
            <label className="d-flex align-items-center">
              <input
                type="checkbox"
                name="terms"
                checked={companyData.terms || false}
                onChange={handleTick}
              />
              <span className="ms-2">
                Я согласен с условиями обработки{" "}
                <a href="#">персональных данных</a>
              </span>
            </label>
          </div>
        </div>
        <div
          className="d-flex gap-md-5 gap-2 w-100 justify-content-end position-sticky pt-4"
          style={{ bottom: 0, left: 0, backgroundColor: "rgb(26, 32, 53)" }}
        >
          <button
            className="btn btn-info w-50 w-lg-25"
            onClick={() => {
              handleClose();
            }}
          >
            Отмена
          </button>
          <div className="position-relative w-50 w-lg-25">
            {currentStep === 2 && (
              <Tooltip
                bottom={
                  width <= 992 && isVisible && !companyData.terms ? 210 : 140
                }
                right={
                  width > 992
                    ? "25%"
                    : isVisible && !companyData.terms
                    ? "50%"
                    : "15%"
                }
              />
            )}
            <button
              className="btn btn-info w-100"
              disabled={!companyData.terms}
              onClick={() => {
                if (isGuideActive && currentStep === 2) {
                  dispatch(nextStep());
                }
                handleSubmit();
              }}
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCompany;
