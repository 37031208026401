import React from "react";
import { useAppDispatch } from "../../store/store";
import { resetGuide } from "../../store/slices/guideSlice";

interface IModalCompany {
  handleClose: () => void;
}

const ModalGuideEnded: React.FC<IModalCompany> = ({ handleClose }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="px-1 flex-1 pb-2 d-flex flex-column">
      <h6>Вы успешно завершили обучение!</h6>
      <div style={{ fontSize: 18, fontWeight: 500 }}>
        Для перехода в базу знаний нажмите по{" "}
        <a
          style={{ color: "lightcyan" }}
          href="https://sites.google.com/view/toriti/%D0%B8%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F-%D0%B4%D0%BB%D1%8F-%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D1%8B"
        >
          ссылке
        </a>
      </div>
      <div
        className="w-auto d-flex flex-column flex-md-row gap-0 align-items-center gap-md-5 pt-4 align-self-md-end align-self-center"
        style={{ float: "right" }}
      >
        <button
          onClick={handleClose}
          className="btn btn-secondary w-100 w-md-auto"
        >
          Ок
        </button>
        <button
          onClick={() => dispatch(resetGuide())}
          className="btn btn-secondary"
        >
          Пройти обучение заново
        </button>
      </div>
    </div>
  );
};

export default ModalGuideEnded;
