import eventSlice from "./slices/eventSlice/eventSlice";
import shiftSlice from "./slices/shiftSlice/shiftSlice";
import employeeSlice from "./slices/employeeSlice/employeeSlice";
import objectSlice from "./slices/objectSlice/objectSlice";
import userSlice from "./slices/userSlice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import checkingSlice from "./slices/checkingSlice/checkingSlice";
import companySlice from "./slices/companySlice/companySlice";
import financeSlice from "./slices/financeSlice/financeSlice";
import guideSlice from "./slices/guideSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    object: objectSlice,
    checking: checkingSlice,
    employee: employeeSlice,
    shift: shiftSlice,
    event: eventSlice,
    company: companySlice,
    finance: financeSlice,
    guide: guideSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
