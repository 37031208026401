import React, { ReactFragment, useEffect, useState } from "react";
import { VscTriangleDown } from "react-icons/vsc";
import { FaChalkboardTeacher } from "react-icons/fa";

interface IFloatBtn {
  tooltip?: string;
  icon?: ReactFragment;
  onClick: () => void;
}

const FloatingButton: React.FC<IFloatBtn> = ({ onClick, icon, tooltip }) => {
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      onMouseLeave={() => setShow(false)}
      className="position-fixed p-3"
      style={
        width > 768
          ? { zIndex: 10000, bottom: 0, left: 0 }
          : { zIndex: 10000, bottom: "26px", left: "0px" }
      }
    >
      <div className="position-relative">
        {show ? (
          <div
            className="position-absolute bg-white rounded p-1"
            style={{
              fontSize: 12,
              width: 160,
              left: "-18px",
              color: "#333",
              top: "-50px",
              zIndex: 1,
            }}
          >
            <div className="position-relative">
              Нажмите, если хотите пройти обучение заново!
              <VscTriangleDown
                className="position-absolute"
                style={{ bottom: -20, left: 20, fontSize: 26, color: "white" }}
              />
            </div>
          </div>
        ) : null}{" "}
        <button
          type="button"
          className="btn btn-secondary btn-lg rounded-circle"
          onClick={onClick}
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          title={tooltip || "Click me"}
          onMouseEnter={() => setShow(true)}
          style={{
            width: "auto",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
            marginBottom: 0,
            fontSize: 18,
            lineHeight: "16px",
            textTransform: "none",
          }}
        >
          {icon || <FaChalkboardTeacher />}
        </button>
      </div>
    </div>
  );
};

export default FloatingButton;
