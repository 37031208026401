import React from "react";
import ReactDOM from "react-dom";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { nextStep, skipGuide } from "../../store/slices/guideSlice";
import {
  VscChromeClose,
  VscTriangleDown,
  VscTriangleLeft,
  VscTriangleUp,
} from "react-icons/vsc";

interface IPosition {
  top?: number | string | null;
  left?: number | string | null;
  bottom?: number | string | null;
  right?: number | string | null;
  width?: number | string | null;
  height?: number | string | null;
  lowArrow?: boolean;
}

const Tooltip: React.FC<IPosition> = (props) => {
  const { steps, currentStep, isGuideActive } = useAppSelector(
    (state) => state.guide
  );
  const { token } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  if (!isGuideActive || !steps[currentStep]) return null;

  const tooltipContent = token ? (
    <>
      {/* <div
        style={{
          position: "absolute",
          top: (position.top || 0) - 5,
          left: (position.left || 0) - 5,
          width: (position.width || 0) + 10,
          height: (position.height || 0) + 10,
          border: "2px solid blue",
          pointerEvents: "none",
          borderRadius: "5px",
          zIndex: 999,
        }}
      /> */}

      <div
        className="bg-dark"
        style={{
          position: "fixed",
          top: props.top || undefined,
          left: props.left || undefined,
          bottom: props.bottom || undefined,
          right: props.right || undefined,
          borderRadius: "5px",
          padding: "10px",
          zIndex: 10001,
          maxWidth: 200,
        }}
      >
        <div className="position-relative w-100 h-100">
          {[0, 1, 3, 6, 13, 15].includes(currentStep) ? (
            <VscTriangleLeft
              color="#344767"
              // size={"small"}
              className="position-absolute"
              style={
                props.lowArrow
                  ? { left: -40, fontSize: 46, bottom: 25 }
                  : { left: -40, fontSize: 46, top: 25 }
              }
            />
          ) : [2, 5, 8, 9, 11, 12].includes(currentStep) ? (
            <VscTriangleDown
              color="#344767"
              // size={"large"}
              className="position-absolute"
              // size={"small"}
              style={{ right: 0, fontSize: 46, bottom: -40 }}
            />
          ) : (
            <VscTriangleUp
              color="#344767"
              // size={"large"}
              className="position-absolute"
              style={{ left: 0, fontSize: 46, top: -40 }}
            />
          )}

          <button
            onClick={() => dispatch(skipGuide())}
            style={{
              float: "right",
              background: "transparent",
              border: "none",
              color: "white",
            }}
          >
            <VscChromeClose />
          </button>
          <p className="text-white lh-1">{steps[currentStep].tooltipContent}</p>
          <button
            className="btn btn-outline-white btn-sm mb-0"
            onClick={() => dispatch(nextStep())}
          >
            Пропустить
          </button>
        </div>
      </div>
    </>
  ) : null;

  return ReactDOM.createPortal(
    tooltipContent,
    document.getElementById("tooltip-root")!
  );
};

export default Tooltip;
