import { createSlice } from "@reduxjs/toolkit";
import { ReactNode } from "react";

interface IStep {
  id: string;
  target: string;
  tooltipContent: string | ReactNode;
  action?: () => void;
}

interface IPosition {
  top: number;
  left: number;
  width: number;
  height: number;
}

interface IState {
  currentStep: number;
  isGuideActive: boolean;
  isGuideFinished: boolean;
  position: IPosition;
  steps: IStep[];
}

const savedStep =
  typeof window !== "undefined"
    ? Number(localStorage.getItem("currentStep")) || 0
    : 0;
const savedState =
  typeof window !== "undefined"
    ? localStorage.getItem("isGuideActive") === "true" || false
    : false;

const initialState: IState = {
  currentStep: savedStep,
  isGuideActive: savedState,
  isGuideFinished: false,
  position: { top: 10, left: 50, width: 0, height: 0 },
  steps: [
    {
      id: "step-0",
      target: ".sidebar-open",
      tooltipContent: "Откройте меню.",
    },
    {
      id: "step-1",
      target: ".sidebar-user-card",
      tooltipContent:
        "Это ваша карточка профиля. Нажмите сюда для редактирования.",
    },
    {
      id: "step-2",
      target: ".modal-input",
      tooltipContent:
        "Заполните все поля, поставьте галочку и нажмите Сохранить",
    },
    {
      id: "step-3",
      target: ".sidebar-guard-card",
      tooltipContent: "Для продолжения перейдите в раздел 'Охранники'.",
    },
    {
      id: "step-4",
      target: ".guard-create",
      tooltipContent:
        "Нажмите сюда для создания учетной записи Сотрудника охраны (эти же данные можно использовать для доступа в мобильном приложении)",
    },
    {
      id: "step-5",
      target: ".guard-save",
      tooltipContent: "Заполните все поля и сохраните данные.",
    },
    {
      id: "step-6",
      target: ".user-menu",
      tooltipContent: "Перейдите в раздел 'Пользователи'.",
    },
    {
      id: "step-7",
      target: ".guard-director-create",
      tooltipContent: "Зарегистрируйте Руководителя ЧОП.",
    },
    {
      id: "step-8",
      target: ".guard-director-select",
      tooltipContent: "Выберите роль 'Руководитель ЧОП'.",
    },
    {
      id: "step-9",
      target: ".guard-director-save",
      tooltipContent: "Заполните оставшиеся поля и сохраните пользователя.",
    },
    {
      id: "step-10",
      target: ".client-create",
      tooltipContent: "Зарегистрируйте Заказчика.",
    },
    {
      id: "step-11",
      target: ".client-select",
      tooltipContent: "Выберите роль 'Заказчик'.",
    },
    {
      id: "step-12",
      target: ".client-save",
      tooltipContent: "Заполните оставшиеся поля и сохраните пользователя.",
    },
    {
      id: "step-13",
      target: ".object-select",
      tooltipContent: "Перейдите в раздел 'Объекты'.",
    },
    {
      id: "step-14",
      target: ".object-create",
      tooltipContent: "Создайте новый Объект.",
    },
    {
      id: "step-15",
      target: ".object-save",
      tooltipContent:
        "Заполните поля, добавьте Заказчика, Охранников и Руководителя ЧОП, если необходимо установите график и длительность обходов, создайте ключи, добавьте адреса и сохраните объект",
    },
  ],
};

const saveCurrentStep = (step: number) => {
  localStorage.setItem("currentStep", String(step));
};

const saveCurrentState = (state: boolean) => {
  localStorage.setItem("isGuideActive", String(state));
};

const guideSlice = createSlice({
  name: "guide",
  initialState,
  reducers: {
    nextStep: (state) => {
      if (state.currentStep < state.steps.length - 1) {
        state.currentStep += 1;
        saveCurrentStep(state.currentStep);
      } else {
        state.isGuideActive = false;
        state.isGuideFinished = true;
        saveCurrentState(state.isGuideActive);
      }
    },
    prevStep: (state) => {
      if (state.currentStep >= 0) {
        state.currentStep -= 1;
        saveCurrentStep(state.currentStep);
      } else {
        state.isGuideActive = false;
        saveCurrentState(state.isGuideActive);
      }
    },
    skipGuide: (state) => {
      state.isGuideActive = false;
      saveCurrentState(state.isGuideActive);
    },
    setStep: (state, action) => {
      state.currentStep = action.payload;
      saveCurrentStep(state.currentStep);
    },
    setPosition: (state, action) => {
      console.log({ action });
      state.position = action.payload;
    },
    resetGuide: (state) => {
      state.currentStep = 0;
      state.isGuideActive = true;
      state.isGuideFinished = false;
      saveCurrentStep(state.currentStep);
      saveCurrentState(state.isGuideActive);
    },
  },
});

export const {
  nextStep,
  skipGuide,
  setStep,
  resetGuide,
  setPosition,
  prevStep,
} = guideSlice.actions;
export default guideSlice.reducer;
