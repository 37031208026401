import imageCompression from "browser-image-compression";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { FileAPI } from "../../api/File/File";
import { SERVER_URL } from "../../helpers/const";
import {
  createEmployee,
  IEmployeeConf,
  updateEmployee,
} from "../../store/slices/employeeSlice/employeeSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import Input from "../Input/Input";
import Tooltip from "../GuideTooltip";
import { nextStep } from "../../store/slices/guideSlice";

interface IModalEmployee {
  mode: "edit" | "create" | "view";
  item?: IEmployeeConf;
  handleClose: () => void;
}

const ModalEmployee: React.FC<IModalEmployee> = ({
  mode,
  handleClose,
  item,
}) => {
  const fileRef = useRef<HTMLDivElement>(null);
  const [uploadingImage, setUploadingImage] = useState(false);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const [imagePath, setImagePath] = useState<string>("");
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [date, setDate] = useState(formattedDate);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [login, setLogin] = useState("");
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const { isGuideActive, currentStep, steps } = useAppSelector(
    (state) => state.guide
  );

  const handleNameChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(e.currentTarget.value);
  };
  const handlePositionChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPosition(e.currentTarget.value);
  };
  const handleDateChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDate(e.currentTarget.value);
  };
  const handlePhoneChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPhone(e.currentTarget.value);
  };
  const handlePasswordChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPassword(e.currentTarget.value);
  };
  const handleTokenChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setToken(e.currentTarget.value);
  };
  const handleLoginChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLogin(e.currentTarget.value);
  };

  const uploadImage = async function (): Promise<void> {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = false;

    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1520,
      useWebWorker: true,
    };

    input.onchange = async (e: Event) => {
      const event = e as Event & { target: object & { files: File | any } };

      const file = await event.target.files[0];

      if (file && file instanceof File) {
        let compressedFile = await imageCompression(file, options);

        setUploadingImage(true);

        let filePath = (await FileAPI.upload(compressedFile)).url;

        // filePath = `${SERVER_URL}/${filePath}`;

        setUploadingImage(false);
        setImagePath(filePath || "");
      }
    };
    if (fileRef.current) {
      fileRef.current.appendChild(input);
    }

    input.click();
  };

  const handleSubmit = () => {
    if (mode === "edit") {
      console.log({
        photo: imagePath,
        name,
        date_of_birth: date,
        login,
        number: phone,
        role: position,
        ...(password ? { password } : {}),
        company_id: user.company_id,
      });
      dispatch(
        updateEmployee({
          id: item?.id,
          photo: imagePath,
          name,
          date_of_birth: date,
          login,
          number: phone,
          role: position,
          ...(password ? { password } : {}),
          company_id: user.company_id,
        })
      );
    } else if (mode === "create") {
      dispatch(
        createEmployee({
          photo: imagePath,
          name,
          date_of_birth: date,
          login,
          number: phone,
          role: position,
          password,
          company_id: user.company_id,
        })
      );
    }
    handleClose();
  };

  useEffect(() => {
    if (mode === "edit" && item) {
      setImagePath(item.photo!);
      setDate(item.date_of_birth!);
      setLogin(item!.login!);
      setName(item.name!);
      // setPassword(item.password!);
      setPhone(item.number!);
      setPosition(item.role!);
      setToken(item.token!);
    }
  }, [mode]);

  return (
    <div className="px-1 h-fill">
      {
        <div
          role="button"
          onClick={uploadImage}
          className="rounded-circle d-flex justify-content-center align-items-center mx-auto  my-3 bg-dark"
          style={{
            width: "160px",
            height: "160px",
            backgroundImage: `url(${SERVER_URL}/get-file/${imagePath})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          {!imagePath && !uploadingImage && (
            <h6 className="m-0">Нет фотографии</h6>
          )}
          {uploadingImage && (
            <div className="spinner-border text-info" role="status"></div>
          )}
        </div>
      }

      {/* <button className='btn btn-info w-100 mt-3' onClick={uploadImage}>
        {
          imagePath ? 'Изменить фото' : 'Загрузить фото'
        }
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={16}
          height={16}
          fill="currentColor"
          className="bi bi-upload ms-3"
          viewBox="0 0 16 16"
        >
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
        </svg>

      </button> */}
      <h6>
        {mode === "create"
          ? "Добавить"
          : mode === "edit"
          ? "Редактировать"
          : "Просмотр"}
      </h6>

      <div className="d-flex flex-column justify-content-between h-fill">
        <div>
          <div className="mb-2">
            {/* <p className='mb-1 text-sm'>ФИО</p> */}
            <div>
              <Input
                placeholder="ФИО"
                value={name}
                handleChange={handleNameChange}
              />
            </div>
          </div>
          <div className="mb-2">
            {/* <p className='mb-1 text-sm'>Должность</p> */}
            <div>
              <Input
                placeholder="Должность"
                value={position}
                handleChange={handlePositionChange}
              />
            </div>
          </div>
          <div className="mb-2">
            <p className="mb-1 text-sm">Дата рождения</p>
            <div>
              <Input
                placeholder=""
                value={date}
                handleChange={handleDateChange}
                type="date"
              />
            </div>
          </div>
          <div className="mb-2">
            {/* <p className='mb-1 text-sm'>Номер тел</p> */}
            <div>
              <Input
                placeholder="Номер тел"
                value={phone}
                handleChange={handlePhoneChange}
              />
            </div>
          </div>
          <div className="mb-2">
            {/* <p className='mb-1 text-sm'>Логин</p> */}
            <div>
              <Input
                placeholder="Логин"
                value={login}
                handleChange={handleLoginChange}
              />
            </div>
          </div>
          <div className="mb-2">
            {/* <p className='mb-1 text-sm'>Пароль</p> */}
            <div>
              <Input
                placeholder="Пароль"
                value={password}
                handleChange={handlePasswordChange}
              />
            </div>
          </div>
          {/* <div className='mb-3'>
        {/* <p className='mb-1 text-sm'>Токен</p>
        <div>
          <Input
            placeholder='Токен'
            value={token}
            handleChange={handleTokenChange}
          />
        </div>
      </div> */}
        </div>
        <div
          className="w-100 mb-0 position-sticky"
          style={{ bottom: 0, left: 0 }}
        >
          {currentStep === 5 && <Tooltip bottom={120} right={"35%"} />}
          <button
            className="btn btn-info w-100 mb-0"
            onClick={() => {
              handleSubmit();
              if (currentStep === 5) dispatch(nextStep());
            }}
          >
            Сохранить
          </button>
        </div>
      </div>
      <div style={{ display: "none" }} ref={fileRef}></div>
    </div>
  );
};

export default ModalEmployee;
