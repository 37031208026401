import React, { useEffect, useState } from "react";
import CardEmployee from "../../components/CardEmployee/CardEmployee";
import Modal from "../../components/Modal/Modal";
import ModalEmployee from "../../components/ModalEmployee/ModalEmployee";
import Pagination from "../../components/Pagination/Pagination";
import Sidebar from "../../components/Sidebar/Sidebar";
import { roles } from "../../navigation/navigation";
import {
  getEmployees,
  IEmployeeConf,
} from "../../store/slices/employeeSlice/employeeSlice";
import { getObjects } from "../../store/slices/objectSlice/objectSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import Tooltip from "../../components/GuideTooltip";
import { nextStep } from "../../store/slices/guideSlice";

const EmployeePage = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.employee.data);
  const total = useAppSelector((state) => state.employee.total);
  const user = useAppSelector((state) => state.user.user);
  const objects = useAppSelector((state) => state.object.data);
  const objectsRequested = useAppSelector((state) => state.object.isRequested);
  const employeesRequested = useAppSelector(
    (state) => state.employee.isRequested
  );
  const [width, setWidth] = useState(window.innerWidth);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [offset, setOffset] = useState(0);
  const [currentItem, setCurrentItem] = useState<IEmployeeConf | null>(null);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const openModal2 = (item: IEmployeeConf) => {
    setCurrentItem(item);
    setOpen2(true);
  };
  const closeModal2 = () => {
    setOpen2(false);
  };

  useEffect(() => {
    if (user.role === roles.client && objectsRequested && employeesRequested) {
      const securityIds: any = objects.map((object) => {
        return JSON.parse(object.ohraniki).map(
          (security: any) => security.value
        );
      });

      dispatch(
        getEmployees({
          limit: 20,
          offset,
          query: securityIds
            .flat()
            .map((id: any) => ({ input: "id", value: id })),
        })
      );
    } else if (
      user.role !== roles.client &&
      objectsRequested &&
      employeesRequested
    ) {
      dispatch(getEmployees({ limit: 20, offset }));
    }
  }, [offset]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!user) return;
    if (user.role === roles.client) {
      if (!objectsRequested) {
        dispatch(
          getObjects({
            limit: 0,
            offset: 0,
            query_and: [{ input: "client_id", value: user.id }],
          })
        );
      } else if (!employeesRequested) {
        const securityIds: any = objects.map((object) => {
          return JSON.parse(object.ohraniki).map(
            (security: any) => security.value
          );
        });

        dispatch(
          getEmployees({
            limit: 20,
            offset: 0,
            query: securityIds
              .flat()
              .map((id: any) => ({ input: "id", value: id })),
          })
        );
      }
    } else if (!objectsRequested && !employeesRequested) {
      dispatch(getObjects({ limit: 0, offset: 0 }));
      dispatch(getEmployees({ limit: 20, offset: 0 }));
    }
  }, [user, employeesRequested, objectsRequested]);

  const { currentStep } = useAppSelector((state) => state.guide);

  const access = [roles.director, roles.headOfSecurity, roles.admin].includes(
    user.role
  );

  return (
    <div>
      <Sidebar />
      <div className="avoidSidebar py-3 pe-3 ps-sm-3">
        <div className="d-flex align-items-center justify-content-between mb-4">
          {access ? (
            <div className="position-relative">
              {currentStep === 4 && (
                <Tooltip top={70} left={width > 1200 ? 260 : 10} />
              )}
              <button
                className="btn btn-info btn-sm m-0 me-1"
                onClick={() => {
                  openModal();
                  if (currentStep === 4) dispatch(nextStep());
                }}
              >
                Добавить
              </button>
              {/* <button className='btn btn-warning btn-sm m-0'>Импорт</button> */}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          {data.map((item: IEmployeeConf, index) => (
            <div className="col-12 col-md-4" key={index}>
              <CardEmployee
                access={access}
                handleOpen={openModal2}
                item={item}
              />
            </div>
          ))}
        </div>
        <Pagination
          offset={offset}
          total={total}
          handleBack={() => {
            if (offset <= 0) return;
            setOffset(offset - 10);
          }}
          handleNext={() => {
            if (offset > total) return;
            setOffset(offset + 10);
          }}
          handleChose={(page) => {
            setOffset(page);
          }}
        />
        <Modal
          open={open}
          closeModal={closeModal}
          children={<ModalEmployee mode="create" handleClose={closeModal} />}
          width={40}
          translateX={70}
        />
        <Modal
          open={open2}
          closeModal={closeModal2}
          children={
            <ModalEmployee
              mode="edit"
              handleClose={closeModal2}
              item={currentItem!}
            />
          }
          width={40}
          translateX={70}
        />
      </div>
    </div>
  );
};

export default EmployeePage;
